<template>
    <nav class="navbar navbar-default navbar-fixed-top vueNav" aria-label="topNavigation">
        <div class="officialBanner hidden">
            <div class="container">
                <div class="excerpt">This is an official government website. 
                    <span class="link" tabindex="0" role="link">Here's how you know <span class="far fa-chevron-down arrow" title="Expand here's how you know content"></span></span>
                    <div class="button" title="Close banner" aria-label="Close Here's How You know banner" role="button" tabindex="0"><span class="fas fa-times"></span></div>
                </div>
                <div class="content">
                    <div class="official col-lg-5 col-md-6 col-sm-6"><div class="circle"><i class="fa-solid fa-landmark"></i></div>
                        <div class="description"><b>Official websites use .gov</b><br />Website addresses ending in .gov belong to official government organizations in the United States.</div>
                    </div>
                    <div class="secure col-lg-5 col-md-6 col-sm-6"><div class="circle"><i class="fa-solid fa-lock"></i></div>
                        <div class="description"><b>Secure .gov websites use HTTPS</b><br />A lock  (<i class="fa-solid fa-lock small"></i>)  or https:// means you've safely connected to the .gov website. Only share sensitive information on official, secure websites.</div>
                    </div>
                </div>
            </div>
        </div>
        <a href="" class="sr-only sr-only-focusable" id="skipToMain">Skip to main content</a>
        <div class="container">
            <div class="navbar-header" id="seagovNavbar">

                <div class="navbar-brand notranslate" title="Seattle.gov logo">
                        <a href='' class="logohome">
                            <p>Seattle</p>
                        </a>
                </div>

                <!-- google translation drop down begin -->
                <div id="translate_gt" class="navbar-text pull-left notranslate">
                    <div class="translate__dropdown">
                        <img class="translate__logo" alt="Google Translate logo" />
                        <div class="translate__label">
                            <button class="translate__link js-choose-lang lang-is-open" title="English" aria-haspopup="listbox" id="translationLabel">English</button> <i class="far fa-chevron-down ga-translate-icon" title="Choose a language arrow"></i>
                        </div>

                        <ul class="menu--lang" role="list" style="display: none;">
                            <li class="menu__item"
                                v-for="(child, i) in languageList"
                                :key="i"
                                role="listitem"
                                >

                                <a class="menu__link translate-link" 
                                    :data-display="child.language"
                                    :data-lang="child.languageCode"
                                    :lang="child.languageCode"
                                    :id="child.language"
                                    role="link"
                                    tabindex="0"
                                    data-domain="www.seattle.gov">{{child.language}}</a>
                            </li>
                        </ul>
                        <script type="application/javascript">
                            var languageCodeList = "{{languageListCode.join(',')}}";
                        </script>
                    </div>
                    <div id="google_translate_element" style="display:none;"></div>
                </div>

                <div class="buttons">
                    <!-- search button -->
                    <button class="navbar-text" id="searchIcon" title="Global search" data-toggle="modal" data-target="#searchModal" tabindex="0">
                        <i class="far fa-search" tabindex="-1"></i>
                        <span class="navbar-button-text">Search</span>
                    </button>

                    <button class="navbar-text" id="supplementalLink" 
                        title="Pay or Apply"
                        :data-url="supplementalUrl"
                        tabindex="0">
                        <span class="navbar-button-text">{{supplementalTitle}}</span>
                    </button>

                    <button 
                        class="navbar-text" id="mainMenuToggle" title="Main Menu" tabindex="0">
                        <i class="far fa-bars" title="Main Navigation Menu" aria-label="Main Navigation Menu" tabindex="-1"></i> 
                        <span class="navbar-button-text">Menu</span>
                    </button>

                    <div id="vue-global-mega-menu-mobile"></div>
                </div>

                <script type="application/javascript" src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"></script>
                <!-- google translation drop down end -->

            </div>
        </div>

       <!-- language disclaimers modal -->
        <div id="disclaimerModal" role="dialog" class="notranslate" aria-label="Google translation disclaimer dialog">
            <div class="padding"
                v-html="disclaimerExcerpts"></div>
            <div class="buttons">
                <a class="btn btn-primary continue" tabindex="0"><p>Continue</p></a>
                <a class="btn btn-default cancel" tabindex="0"><p>Cancel</p></a>
            </div>
            <div id="disclaimerLink" class="hidden" 
                :data-url="disclaimerUrl"
                :data-target="disclaimerTarget">
                </div>
        </div>

        <!-- search modal -->
        <div class="modal fade vue" id="searchModal" tabindex="-1" role="dialog" aria-labelledby="searchIcon">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" aria-label="Close global search" data-dismiss="searchModal" id="searchModalClose" data-toggle="modal" data-target="#searchModal">
                            <span class="fas fa-times fa-2x"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form role="search" id="googleSearch" class="navbar-form">
                            <div class="input-group">
                                <input type="text" class="form-control" title="Search" id="searchInput" name="terms" size="18" maxlength="255" value="" placeholder="Search">
                                <span class="input-group-btn">
                                    <button id="searchButton" type="submit" class="btn btn-primary btn-default" aria-label="Submit search button"><i class="fas fa-search fa-2x"></i></button>
                                </span>
                                <input type="hidden" name="site" value="seagov" />
                            </div>  
                            <div id="googleSearchToggle"
                                v-if="hasDepartmentSearchCode" 
                                >
                                <fieldset class="searchToggles">
                                    <legend> <span> I want to search within the following:</span> </legend>
                                    <div class="radio">
                                        <label for="Seattlegov">
                                            <input type="radio" name="catids" value="" title="Seattlegov" id="Seattlegov" checked="checked">
                                            <span>Seattle.Gov</span>
                                        </label>
                                        <label for="deptCollection">
                                            <input type="radio" name="catids" 
                                                :value="departmentSearchCode" 
                                                title="Current Site" id="deptCollection">
                                            <span>This Site Only</span>
                                        </label>
                                    </div>
                                </fieldset>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <!-- mobile background -->
        <div id="mobileBackground" class="modal fade vue">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-body"></div>
                </div>
            </div>
        </div>

    </nav>
</template>

<script>
    export default {
        name: 'GlobalHeader',
        props: {
            id: String
        },
        data() {
            return {
                languageXID: '',
                languageKey: [],
                languageList: [],
                languageListCode: [],
                disclaimerUrl: '',
                disclaimerTarget: '',
                disclaimerExcerpts: '',
                departmentTopNavXID: '',
                departmentSearchCode: '',
                isDepartment: '',
                serverPath: '',
                supplementalUrl: '',
                supplementalTitle: ''
            }
        },
        created() {
            this.getServerPath()
            this.getXIDs()
            this.getHeaderData()

            if (this.isDeparmentMode)
            {
                this.getDepartmentTopNav()
            }
        },
        mounted() {
        },
        ready() {
        },
        computed: {
            //temporarily enable menu sitewide untiil the mega menu is built
            isDeparmentMode() { return this.isDepartment == 'True' ? true : false },
            hasDepartmentSearchCode() { return this.departmentSearchCode.length > 0 }
        },
        components: {
        },
        destroyed () {
        },
        methods: {
            getServerPath() {
                const url = window.location.href.toLowerCase(),
                    locations = ['.gov', 'dsspreview', '_dss', '//localhost/jay105_dss', 'localhost:8080', 'cenwinw102', 'cenwinw103', 'cenwinw104', 'itdwseadotgv100.ci.seattle.wa.us', 'itdwigxcms501/seattle', 'itdwigxcms501/qaseattle'],
                    checkNavURL = str => {
                        if (url.includes(str)) this.serverPath = url.split(str)[0] + str + '/'
                    }

                locations.forEach(item => checkNavURL(item))
            },
            getXIDs() {
                this.languageXID = window.languagesListXID || ''
                this.departmentTopNavXID = window.topNavXID || ''
                this.isDepartment = window.isDepartmentMode || ''
            },
            async getHeaderData() {
                let apiServer = (this.serverPath.includes('localhost') || this.serverPath.includes('8')) ? '//localhost/jay105_dss/' : this.serverPath

                //console.log(apiServer +'api/content/'+ this.languageXID +'/elements/LanguagesList,Page,DisclaimerLanguageExcerpts')

                try {
                    const api = `${ apiServer }api/content/${ this.languageXID }/elements/LanguagesList,Page,DisclaimerLanguageExcerpts`,
                            response = await fetch(api, { cache: 'force-cache' }),
                            data = await response.json()

                    //console.log('fetch response: ', response)
                    //console.log('fetch data: ', data)

                    const dataTree = data.elements

                    //get the languages
                    let getLanguages = (languageList) => {
                        for (const item of languageList) {
                            if (item.name == 'LanguagesList') {
                                var splitValue = item.value.split('|')

                                for(var i = 0; i < splitValue.length; i++)
                                {
                                    var languageSet = splitValue[i]
                                    var splitLanguageSet = languageSet.split(' - ')

                                    this.languageList.push({
                                        language: splitLanguageSet[0],
                                        languageCode: splitLanguageSet[1]
                                    })

                                    this.languageListCode.push(splitLanguageSet[1])

                                }
                            } 
                        }
                    }

                    getLanguages(dataTree)

                    //get the disclaimer link
                    let getDisclaimerLink = (disclaimerUrl) => {
                        for (const item of disclaimerUrl) {
                            if (item.attributes.name == 'DisclaimerLink') {
                                //disclaimer url
                                this.disclaimerUrl = item.attributes.url
                                //disclaimer target
                                this.disclaimerTarget = item.attributes.target
                            } 
                        }
                    }

                    getDisclaimerLink(dataTree)

                    //get the disclaimer language excerpts
                    let getExcerpts = (disclaimerExcerpts) => {
                        for (const item of disclaimerExcerpts) {
                            if (item.name == 'DisclaimerLanguageExcerpts') {
                                //get the copy
                                this.disclaimerExcerpts = item.value
                            } 
                        }
                    }

                    getExcerpts(dataTree)

                    //get the supplemental link
                    let getSupplementalLink = (supplementalUrl) => {
                        for (const item of supplementalUrl) {
                            if (item.attributes.name == 'SupplementalLink') {
                                //supplemental url
                                this.supplementalUrl = item.attributes.url
                                //supplemental target
                                this.supplementalTitle = item.attributes.title
                            } 
                        }
                    }

                    getSupplementalLink(dataTree)
                } catch (err) {
                    console.log('GlobalHeader > getHeaderData. Error. ' + err) /* eslint-disable-line */
                }
            },

            async getDepartmentTopNav() {

                let apiServer = (this.serverPath.includes('localhost') || this.serverPath.includes('8')) ? '//localhost/jay105_dss/' : this.serverPath

                //console.log(apiServer +'api/content/'+ this.departmentTopNavXID +'/elements/DeptGoogleSearchCode')

                try {
                    const api = `${ apiServer }api/content/${ this.departmentTopNavXID }/elements/DeptGoogleSearchCode`,
                            response = await fetch(api, { cache: 'force-cache' }),
                            data = await response.json()

                    // console.log('fetch response: ', response)
                    // console.log('fetch data: ', data)

                    const dataTree = data.elements

                    //get the department top nav search code
                    let getDepartmentSearchCode = (departmentSearchCode) => {
                        for (const item of departmentSearchCode) {
                            if (item.name == 'DeptGoogleSearchCode') {
                                this.departmentSearchCode = item.value
                            } 
                        }
                    }

                    getDepartmentSearchCode(dataTree)

                } catch (err) {
                    console.log('GlobalHeader > getDepartmentTopNav. Error.') /* eslint-disable-line */
                }
            }
        }
    }

</script>

<style lang="scss">
    /* New global header styling */
    .navbar-default {
        border-radius: 0;
        width: 100%;
        z-index: 1030;

        &.navbar {
            position: fixed;
        }

        .officialBanner {
            display: none;
            background-color: #333;
            min-height: 29px;
            color: #fff;
            font-size: 12px;

            &.display {
                display: grid;
            }

            .excerpt {
                margin-left: 14px;
                padding-top: 5px;

                .link {
                    color: #fff;
                    font-weight: bold;
                    text-decoration: underline;
                    cursor: pointer;

                    svg.arrow {
                        width: 7px;
                        height: 11px;
                        margin-left: 4px;
                    }
                }

                .button {
                    float: right;
                    top: 1px;
                    position: relative;
                    cursor: pointer;

                    svg.fa-xmark {
                        width: 12px;
                        height: 12px;
                    }
                }

                &.wrap {
                    .link {
                        display: block;
                    }

                    .button {
                        top: -25px;
                        right: 10px;
                    }
                }
            }

            .content {
                margin: 30px 0 26px 4px;
                display: none;
                font-size: 16px;

                &.expanded {
                    display: table;
                }

                &.collapsed {
                    display: none;
                }

                .official, .secure {
                    float: left;

                    svg:not(.small) {
                        position: relative;
                        z-index: 1;
                        float: left;
                        height: 12px;
                        left: 9px;
                        top: 9px;
                    }

                    .description {
                        width: 90%;
                        float: left;
                        margin: 0 0 0 -5px;
                        padding: 0;
                    }
                }

                .circle {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    border-style: solid;
                    border-width: 1px;
                    border-color: #fff;
                    background-color: rgba(0, 0, 0, 0);
                    float: left;
                    margin-right: 12px;
                }

                .official {
                    .fa-landmark {
                        width: 12px;
                    }
                }

                .secure {
                    .fa-lock:not(.small) {
                        width: 11px;
                    }
                }
            }
        }

        .navbar-header {

            .navbar-brand {
                padding-right: 0 !important;

                .logohome {
                    display: table;

                    &:before {
                        margin-top: -14px;
                    }

                    p {
                        float: left;
                        margin-top: -2px;
                        display: table;
                        margin-bottom: 0;
                        padding-right: 24px; 
                        font-size: 32px;
                        top: 1px;
                        position: relative;
                    }
                }

            }

            .navbar-text {
                &:not(.expanded):hover {
                    background-color: #515151;
                    cursor: pointer;
                }

                .navbar-button-text {
                    font-weight: normal !important;
                    font-family: 'Seattle Text', Regular !important;
                    position: relative;
                    top: 2px;
                }
            }

            #translate_gt {
                position: relative; 
                height: 60px;
                font-size: 16px;
                //z-index: 1002;
                width: 140px;
                border-left: solid 2px transparent;

                &.mobile {
                    .translate__dropdown {  
                        .translate__link {
                            font-size: 14px;
                        }
                    }
                }

                &:after {
                    content: '';
                    border-left: solid 1px #fff;
                    position: relative;
                    top: -13px;
                    height: 34px;
                    display: inline-block;
                    left: -1px;
                }

                &.expanded {
                    background-color: #fff;

                    &:after {
                        border-left: none;
                    }
                }

                &.navbar-text {
                    &.pull-left {
                        margin: 0;
                    }
                }

                .dropdown-backdrop {
                    display: none;
                }

                .translate__dropdown {  
                    min-width: auto;
                    max-width: auto;
                    display: table; 
                    position: relative;
                    top: 18px;

                    .translate__logo {
                        content: url(//www.seattle.gov/images/global/topnav/Google_Translate_Icon-24.png);
                        float: left;
                        width: 24px;
                        margin: 0 8px 0 25px; 
                    }

                    .translate__label {
                        float: left;
                        cursor: pointer;
                        display: block;
                        padding: 1px 0;
                        white-space: nowrap;

                        button {
                            color: #fff;
                            line-height: 24px;
                            top: -1px;
                            position: relative;
                        }

                        svg {
                            color: #fff;
                            margin: 0 5px 0 5px;
                            position: relative;
                            top: -1px;
                            width: 12px;
                        }

                        .translate__link {
                            cursor: pointer;
                        }

                                            
                        &.expanded {
                            button, svg {
                                color: #003da5; 
                            }
                        }
                    }

                    .menu--lang {
                        list-style: none;
                        margin: 18px 0 0 -2px;
                        float: left;
                        position: relative;
                        width: 100%;
                        column-count: 2;
                        column-gap: 40px;
                        border-left: solid 1px #f1f1f1;
                        border-bottom: solid 1px #f1f1f1;
                        border-right: solid 1px #f1f1f1;
                        background-color: #fff;
                        border-bottom-left-radius: 4px;
                        border-bottom-right-radius: 4px;

                        .menu__item {
                            margin: 0;
                            margin-left: -40px;
                            min-width: 160px;
                            cursor: pointer;
                            transition-duration: 100ms;

                            &:hover {
                                background-color:#f1f1f1;
                            }

                            a {
                                position: relative;
                                left: 20px;
                                line-height: 40px;
                                color: #333;

                                &.selected {

                                    &:after {
                                        content: url(//www.seattle.gov/images/global/topnav/check.svg);
                                        position: relative;
                                        color: black;
                                        padding-left: 7px;
                                        top: 4px;
                                        left: 0;
                                    }
                                }

                                &:hover {
                                    text-decoration: none;
                                }
                            }

                            &.borderLeft {
                                border-left: solid 1px #f1f1f1;
                            }
                        }

                        .lightGray {
                            background-color: #f5f5f5;
                        }
                    } 
                } 
            }

            .buttons {
                float: right;

                #supplementalLink, #searchIcon, #mainMenuToggle, #mobileMegaMenuToggle {
                    width: 120px;
                    height: 60px;
                    cursor: pointer;

                    &.navbar-text {
                        margin: 0;
                    }

                    &:hover {
                        background-color: #515151;
                        text-decoration: none;
                        cursor: pointer;
                    }
                }

                #supplementalLink {
                    margin-right: 0 !important;
                    background-color: #00317a;
                }

                #searchIcon {

                    svg {
                        font-size: 18px;
                        position: relative;
                        top: 2px;
                    }
                }

                #mobile-mega-menu {
                    float: right;
                }
            }

            #mainMenuToggle, #mobileMegaMenuToggle {
                margin-top: 0 !important;
                margin-bottom: 0 !important;

                svg {
                    font-size: 18px;
                    position: relative;
                    top: 2px;
                }
            }
        }

        @media (min-width: 768px) and (max-width: 1291px) {
            .officialBanner {
                .content {
                    &.expanded {
                        .official, .secure {
                            margin: 0;

                            .description {
                               width: 88%;
                            }
                        }

                        .official {
                            .description {
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
            }
        }

        @media (min-width: 768px) {
            .navbar-brand {
                margin-left: -15px;
            }
        }

        @media (max-width: 767px) {
            .officialBanner {
                .content {
                    &.expanded {
                        .official, .secure {
                            margin: 0;

                            .description {
                               width: 84%;
                            }
                        }

                        .official {
                            .description {
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
            }

            .navbar-header {
                #supplementalLink {
                    display: none;
                }

                .buttons {
                    #searchIcon, #mainMenuToggle, #mobileMegaMenuToggle {
                        &:hover {
                            background-color: #003da5;
                        }
                    }

                    #searchIcon {
                        width: 40px;
                        float: left;

                        svg {
                            font-size: 1.3333333333em;
                        }
                    }

                    #mainMenuToggle, #mobileMegaMenuToggle {
                        width: 56px;
                        float: right;
                        padding-right: 20px;
                        text-align: center;
                        margin: 9px 0;

                        .navbar-button-text {
                            display: block;
                            font-size: 12px;
                            overflow-wrap: break-word;
                        }

                        svg {
                            font-size: 24px;
                            display: block;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }

        @media (max-width: 575px) {
            .navbar-header {
                .navbar-brand {
                    padding-right: 0px !important;

                    .logohome {
                        p {
                            padding-right: 12px;
                            margin-top: -3px;
                            font-size: 21px;
                        }
                    }
                }

                #translate_gt {
                    max-width: 130px;
                        .translate__dropdown {
                            .translate__logo {
                                display: none;
                            }
                            .translate__label {
                                padding: 0 0 0 13px;

                                    svg {
                                        top: 0;
                                    }
                            }
                        }
                }

                .buttons {

                    button {
                        float: left;
                    }

                    #searchIcon {
                        margin-left: 5px;
                        padding-right: 0 !important;

                        &.navbar-text {
                                &.marginRight {
                                margin-right: 5px;
                            }

                            svg {
                                position: relative;
                                top: 1px;            
                            }
                        }
                    }

                    #mainMenuToggle, #mobileMegaMenuToggle {
                        width: 53px;
                    }

                }
            }
        }

        @media (max-width: 440px) {
            .officialBanner {
                .container {
                    margin-bottom: -10px;
                }
            }
        }

        @media (max-width: 360px) {
            .officialBanner {
                .content {
                    &.expanded {
                        .official {
                            .description {
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
            }

            .navbar-header {
                #translate_gt {
                    max-width: none;
                    width: 100px;
                }
            }
        }

        #disclaimerModal {
            display: none;
            margin-left: 21px;
            width: 195px;
            background-color: #f1f1f1;
            word-wrap: break-word;
            z-index: 1051;
            font-size: 14px;
            box-shadow: 0px 1px 1px rgba(0,0,0,0.2);
            color: #000;

            .language {
                padding: 0 10px;

                .phone {
                    pointer-events: none;
                    color: #000;
                }
            }

            .buttons {
                display: none;
            }

            .padding {
                background-color: #f1f1f1;
                padding: 10px 0;
                position: relative;
                z-index: 1052;
            }

            &.mobile {
                position: absolute;
                background-color: #fff;
                width: 90%;
                border-radius: 4px;
                top: 50%; 
                left: 50%; 
                margin-left: 0;
                -webkit-transform: translate(-50%,-50%);
                -ms-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);

                .padding {
                    background-color: #fff;
                    padding: 20px;
                }

                .buttons {
                    display: block;
                    padding: 20px 20px 10px 20px;

                    .btn {
                        height: 40px;
                        min-height: 40px;
                        width: 100%;
                        margin-bottom: 10px;
                        left: 50%;

                        &:hover {
                            background-color: #000;
                        }

                        p {
                            padding-top: 2px;
                        }
                    }

                    .continue {
                        background-color: #003da5;
                    }

                    .cancel {
                        color: #003da5;
                        background-color: #fff;

                        &:hover {
                            color: #fff;
                        }

                        svg {
                            position: relative;
                            top: 1px;
                            font-size: 18px;
                            font-weight: normal;
                        }
                    }

                }
            }

            a {
                font-weight: bold;

                &:hover {
                    background: #003da5;
                    color: #fff;
                    text-decoration: none;
                }
            }

            &.pointer { 

                &.switch {

                    &:before {
                        content: '';
                        position: absolute;     
                        width: 22px;
                        height: 22px;
                        top: auto;
                        bottom: 9px;
                        box-shadow: 2px 0px 0px 2px rgba(0, 0, 0, 0.2);
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        top: auto;
                        bottom: 5px;
                        border-top: 15px solid transparent;
                        border-bottom: 15px solid transparent;
                    }

                    &.left {

                        &:before {
                            transform: rotate(45deg);
                            right: -8px;
                        }

                        &:after {
                            right: -15px;
                            border-right: none;
                            border-left: 15px solid #f1f1f1;
                        }
                    }
                }

                &:before {
                    content: '';
                    cursor: pointer;
                    position: absolute;     
                    width: 22px;
                    height: 22px;
                    top: 8px;
                    box-shadow: 2px 0px 0px 2px rgba(0, 0, 0, 0.2);
                }

                &:after {
                    content: "";
                    cursor: pointer;
                    position: absolute;
                    top: 4px;
                    border-top: 15px solid transparent;
                    border-bottom: 15px solid transparent;
                }

                &.left {

                    &:before {
                        transform: rotate(45deg);
                        right: -8px;
                    }

                    &:after {
                        right: -15px;
                        border-right: none;
                        border-left: 15px solid #f1f1f1;
                    }
                }

                &.right {

                    &:before {
                        transform: rotate(135deg);
                        left: -8px;
                    }

                    &:after {
                        left: -15px;
                        border-left: none;
                        border-right: 15px solid #f1f1f1;
                    }
                }
            }

            &.left {
                border-left-width: 1px;
                border-left-color: rgb(241, 241, 241);
                border-left-style: solid; 
            }

            &.right {
                margin-left: 0px;
                border-right-width: 1px;
                border-right-color: rgb(241, 241, 241);
                border-right-style: solid; 
            }

            @media (max-width: 575px) {
                .language {
                    .phone {
                        pointer-events: auto;
                        color: #0047ba;
                    }
                }
            }
        }

        #mobileBackground {
            padding: 0px;
            border: 0px;
            background: rgba(0,0,0,0);
            border-radius: 0px;
            left: 0px;
            margin-left: 0px;
            width: 100%;

            .modal-dialog {
                width: 100%;
                height: 100%;
                margin: 0 auto;

                .modal-content {
                    height: 100%;
                    border-radius: 0;
                    opacity: 1;
                    background-color: rgba(0,0,0,0.6);
                }
            }
        }
    }    
</style>